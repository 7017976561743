var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('section',{staticClass:"bg-white"},[_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"card border-dark"},[_vm._m(0),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped log-table-new mb-0"},[_vm._m(1),_c('tbody',_vm._l((_vm.nftSaleLists.results),function(nftSale,index){return _c('tr',{key:index,staticClass:"align-middle"},[_c('td',[_c('div',{staticClass:"align-middle text-center ps-1"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('td',{staticClass:"text-start"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center gap-3"},[_c('img',{staticClass:"img-responsive rounded-10",attrs:{"src":nftSale.logo,"width":"40px","height":"40px","alt":""}}),_c('div',[(nftSale.protocol === 'ethereum')?_c('router-link',{staticClass:"fw-bold text-decoration-none text-dark text-break text-nowrap",attrs:{"to":'/nft-details/' +
                            nftSale.collection +
                            '/' +
                            nftSale.id}},[_vm._v(_vm._s(_vm._f("truncateFirst")(nftSale.name,26, "...")))]):_c('span',{staticClass:"fw-bold text-decoration-none text-dark text-break"},[_vm._v(" "+_vm._s(_vm._f("truncateFirst")(nftSale.name,26, "..."))+" ")]),_c('div',{staticClass:"d-flex justify-content-start align-items-center gap-1"},[_c('img',{attrs:{"src":nftSale.collectionLogo,"width":"15px","height":"15px","alt":""}}),_vm._v(" "+_vm._s(nftSale.collectionName)+" ")])],1)])]),_c('td',{staticClass:"text-start text-nowrap"},[_vm._v(" "+_vm._s(_vm.moment(nftSale.soldAt).fromNow())+" ")]),_c('td',{staticClass:"text-start"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v("$"+_vm._s(_vm._f("formatNumber")(nftSale.priceInFiat)))]),_c('small',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(nftSale.priceInCrypto)+" "+_vm._s(nftSale.tokenSymbol)+" ")])])]),_c('td',{staticClass:"text-end"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center gap-2"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("truncateFirst")(nftSale.seller,8, "..."))+" ")]),_vm._m(2,true),_c('div',[_vm._v(_vm._s(_vm._f("truncateFirst")(nftSale.buyer,8, "...")))])])])])}),0)])]),_c('div',{staticClass:"row mt-3"},[_c('ul',{staticClass:"pagination pagination-md justify-content-center text-center"},[_c('li',{staticClass:"page-item",class:_vm.page === 1 ? 'disabled' : ''},[_c('a',{class:_vm.page === 1
                      ? 'page-link bg-outline-success border-success text-success'
                      : 'page-link bg-success border-success text-white',on:{"click":_vm.prevPage}},[_vm._v(" Previous ")])]),_c('li',{staticClass:"page-link text-success border-success border-start-0 border-end-0",staticStyle:{"background-color":"inherit"}},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.lastPage)+" ")]),_c('li',{staticClass:"page-item",class:_vm.page === _vm.lastPage ? 'disabled' : ''},[_c('a',{class:_vm.page === _vm.lastPage
                      ? 'page-link bg-outline-success border-success text-success'
                      : 'page-link bg-success border-success text-white',on:{"click":_vm.nextPage}},[_vm._v(" Next ")])])])])])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-dark text-white"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',{staticClass:"mb-0 py-2"},[_vm._v("Top Sales Nft")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td'),_c('td',[_vm._v("NFT")]),_c('td',[_vm._v("Time")]),_c('td',[_vm._v("Price")]),_c('td',{staticClass:"text-end"},[_vm._v("Transfer")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('em',{staticClass:"ri ri-file-transfer-line text-success"})])
}]

export { render, staticRenderFns }