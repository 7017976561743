<template>
  <div>
    <Header />
    <section class="bg-white">
      <div class="container py-4">
        <div class="card border-dark">
          <div class="card-header bg-dark text-white">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 py-2">Top Sales Nft</h6>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped log-table-new mb-0">
                <thead>
                  <tr>
                    <td></td>
                    <td>NFT</td>
                    <td>Time</td>
                    <td>Price</td>
                    <td class="text-end">Transfer</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(nftSale, index) in nftSaleLists.results"
                    :key="index"
                    class="align-middle"
                  >
                    <td>
                      <div class="align-middle text-center ps-1">
                        {{ index + 1 }}
                      </div>
                    </td>
                    <td class="text-start">
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="nftSale.logo"
                          class="img-responsive rounded-10"
                          width="40px"
                          height="40px"
                          alt=""
                        />
                        <div>
                          <router-link
                            v-if="nftSale.protocol === 'ethereum'"
                            :to="
                              '/nft-details/' +
                              nftSale.collection +
                              '/' +
                              nftSale.id
                            "
                            class="
                              fw-bold
                              text-decoration-none
                              text-dark
                              text-break
                              text-nowrap
                            "
                            >{{
                              nftSale.name | truncateFirst(26, "...")
                            }}</router-link
                          >
                          <span
                            v-else
                            class="
                              fw-bold
                              text-decoration-none text-dark text-break
                            "
                          >
                            {{ nftSale.name | truncateFirst(26, "...") }}
                          </span>
                          <div
                            class="
                              d-flex
                              justify-content-start
                              align-items-center
                              gap-1
                            "
                          >
                            <img
                              :src="nftSale.collectionLogo"
                              width="15px"
                              height="15px"
                              alt=""
                            />
                            {{ nftSale.collectionName }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-start text-nowrap">
                      {{ moment(nftSale.soldAt).fromNow() }}
                    </td>
                    <td class="text-start">
                      <div class="d-flex flex-column">
                        <div>${{ nftSale.priceInFiat | formatNumber }}</div>
                        <small class="text-secondary">
                          {{ nftSale.priceInCrypto }} {{ nftSale.tokenSymbol }}
                        </small>
                      </div>
                    </td>
                    <td class="text-end">
                      <div
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          gap-2
                        "
                      >
                        <div>
                          {{ nftSale.seller | truncateFirst(8, "...") }}
                        </div>
                        <div>
                          <em
                            class="ri ri-file-transfer-line text-success"
                          ></em>
                        </div>
                        <div>{{ nftSale.buyer | truncateFirst(8, "...") }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row mt-3">
              <ul
                class="
                  pagination pagination-md
                  justify-content-center
                  text-center
                "
              >
                <li class="page-item" :class="page === 1 ? 'disabled' : ''">
                  <a
                    :class="
                      page === 1
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="prevPage"
                  >
                    Previous
                  </a>
                </li>
                <li
                  class="
                    page-link
                    text-success
                    border-success border-start-0 border-end-0
                  "
                  style="background-color: inherit"
                >
                  {{ page }} of {{ lastPage }}
                </li>
                <li
                  class="page-item"
                  :class="page === lastPage ? 'disabled' : ''"
                >
                  <a
                    :class="
                      page === lastPage
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="nextPage"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "NftSalesList",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      nftSaleLists: [],
      moment: moment,
      nftPage: 1,
      page: 1,
      perPage: 15,
    };
  },
  computed: {
    showRepos() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      return this.nftSaleLists.results.slice(start, end);
    },
    lastPage() {
      let length = this.nftSaleLists.pageCount;
      return length;
    },
  },
  mounted() {
    this.getNftSaleList();
  },
  created() {
    this.$mixpanel.track("Visit NFT Sales List ");
  },
  methods: {
    getNftSaleList() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/sale/day?limit=15&page=" +
            this.page +
            "&currency=USD&sort=priceInFiat&order=desc&excludeDappId=12792"
        )
        .then((res) => {
          this.nftSaleLists = res.data;
        });
    },
    prevPage() {
      this.page--;
      this.getNftSaleList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    nextPage() {
      this.page++;
      if (this.page == this.lastPage - 1) {
        this.nftPage++;
        this.getNftSaleList();
      }
      this.getNftSaleList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
